@use '@styles/_partials' as *;

.videoSection {
  position: relative;
  z-index: 10;
  padding-bottom: pxToRem(50px);
}

.videoContainer {
  background-image: linear-gradient(180deg, $offBlackXLt 55%, $offBlack 100%);
}

.videoContainerNoBkgd {
  .vimeoWrapper {
    margin: 0;
    width: 100%;
    @include respond-to($lg) {
      margin-top: pxToRem(20px);
      margin-bottom: pxToRem(20px);
    }

    iframe {
      background-image: linear-gradient(
        180deg,
        $offBlackXLt 55%,
        $offBlack 100%
      );
    }
  }
}

.vimeoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: pxToRem(5px) pxToRem(10px);
  width: calc(100% - 20px);
  @include respond-to($md) {
    margin: 20px 10px;
  }
  @include respond-to($lg) {
    margin: pxToRem(40px) auto 0;
    width: calc(100% - 80px);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.swiper {
  width: calc(100% - 30px);
  @include respond-to($md) {
    margin-top: pxToRem(20px);
    width: calc(100% - 100px);
  }

  :global {
    // swiper arrow navigation size
    --swiper-navigation-size: 24px;
    @include respond-to($md) {
      --swiper-navigation-size: 44px;
    }
  }
}

.swiperOuterContainer {
  display: flex;
  align-items: center;

  @include respond-to($md) {
    padding: 0 pxToRem(40px);
  }
}

.swiperButtonPrev,
.swiperButtonNext {
  color: $white;
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-bottom: pxToRem(10px);
  background-size: 10px 20px;
  width: 40px;
  height: 40px;
  @include respond-to($md) {
    margin-bottom: 0;
    background-size: 20px 40px;
    height: 58px;
  }
  @include respond-to($xl) {
    background-size: 27px 58px;
  }

  &:global(.swiper-button-disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.swiperButtonPrev {
  transform: rotate(180deg);
}

.swiperSlide {
  cursor: pointer;
  position: relative;
  text-align: center;
  margin: pxToRem(5px) 0;
  padding: 0 pxToRem(2px);
  box-sizing: border-box;

  .slideButton {
    position: absolute;
    top: 0;
    left: 2px;
    right: 2px;
    bottom: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    padding: 0;

    @include respond-to($xs) {
      padding: 0 pxToRem(6px);
    }
  }

  .vimeoTitle {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, border 0.2s ease;
    color: $white;
    font-weight: 700;
    @include font-size(10px);
    @include line-height(12);
    @include respond-to($md) {
      @include font-size(14px);
      @include line-height(16);
    }
  }

  // nextjs image div wrapper
  div {
    border-radius: 5px;
    border: 1px solid transparent;
  }

  &.swiperSlideActive,
  &.swiperSlideFocused {
    div {
      border-radius: 5px;
      border: 1px solid $primary;
    }

    & .vimeoTitle {
      opacity: 1;
      visibility: visible;
    }
  }
}

.closeModal {
  position: absolute;
  top: 0;
  right: 15px;
  height: 44px;
  width: 44px;
  background: transparent;
  padding: 0;
  z-index: 1;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include respond-to($md) {
    top: 30px;
    right: 30px;
  }

  span {
    border: 2px solid $white;
    color: $white;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    @include font-size(16px);
    @include line-height(16);
  }

  &:hover span::after {
    content: '';
    width: 44px;
    height: 44px;
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    background-image: radial-gradient(
      circle 22px at center center,
      rgba($offBlack, 0.5) 0px,
      rgba($offBlack, 0.25) 80%,
      rgba($offBlack, 0) 100%
    );
    z-index: -1;
  }
}

.vimeoThumbnails {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
