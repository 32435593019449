@use '@styles/_partials' as *;

@media (prefers-reduced-motion: no-preference) {
  :global(html[dir='rtl']) .inViewport {
    .backgroundVideo {
      animation: revealItemFromLeft forwards
        calc($animationDuration + $animationDelayAddend);
    }
  }
}
