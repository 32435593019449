@use '@styles/_partials' as *;
@use './CampaignVimeoModal.rtl.scss';

.sectionContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;

  @include respond-to-max-width($maxMd) {
    background-image: none !important;
  }

  // To account for background video sizing in mobile
  @include respond-to-max-width($xxs) {
    margin-top: 0;
  }

  :global .container {
    display: flex;
    position: relative;
    width: 100%;
    max-height: 100%;
    height: 100%;
    justify-content: center;

    .row {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      z-index: 2;
      width: 100%;
      @include respond-to($lg) {
        flex-direction: row;
      }
    }
  }
}

.titleImage {
  // Set explicit width and height for lighthouse performance gains
  img {
    width: fit-content;
    height: auto;
  }
}

.backgroundVideo {
  background-color: transparent;
  z-index: 2;
  display: flex;
  justify-content: center;
  position: relative;
  height: 480px;

  @include respond-to($md) {
    height: 600px;
  }

  @include respond-to($lg) {
    height: 700px;
  }

  .backgroundVideoWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .facadeImage {
    z-index: 0;
    position: absolute;
    // Slightly smaller width and height to not be visible behind video
    width: 99.5%;
    height: 99.5%;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  .player {
    position: relative;

    & :global(button.play) {
      display: none;
    }
  }
}

.body {
  p {
    text-shadow: 0 0 10px rgb(67 70 84 / 100%);
  }
}

.textContainer {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include font-size(18px);
  @include line-height(28);
  @include respond-to($md) {
    @include font-size(24px);
    @include line-height(34);
  }
  @include respond-to($lg) {
    padding-left: pxToRem(50px);
    padding-right: pxToRem(50px);
  }

  .innerContainer {
    display: block;
  }

  img {
    max-width: 100%;
    margin-top: pxToRem(20px);
    margin-bottom: pxToRem(20px);
  }
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: pxToRem(20px);

  button,
  a {
    margin-left: pxToRem(15px);
    margin-top: pxToRem(20px);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .backgroundVideo,
  .titleImage img,
  .body,
  .submitButton {
    opacity: 0;
  }

  .inViewport {
    .backgroundVideo {
      animation: revealItemFromRight forwards
        calc($animationDuration + $animationDelayAddend);
    }
    .titleImage img {
      animation: revealItem forwards $animationDuration;
    }
    .body {
      animation: revealItem forwards $animationDuration
        calc($animationDelay - $animationDelayAddend);
    }
    .submitButton {
      animation: revealItem forwards $animationDuration $animationDelay;
    }
  }
}
